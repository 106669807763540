.fc {
  .fc-h-event {
    background-color: transparent !important;
    border: none !important;

    .fc-event-main {
      color: #000000 !important;
    }
  }

  .fc-col-header-cell {
    background-color: #2a7871;
    color: #ffffff;
    font-weight: 400;
    padding: 14px 0;
  }

  .fc-scrollgrid {
    border: none !important;
  }

  .fc-daygrid-day-top {
    flex-direction: row !important;
    font-weight: 700;
    font-size: 32px;
  }

  .fc-day {
    cursor: pointer;

    &-past {
      background-color: #ebebeb;
    }
    &-today {
      background-color: rgba(#2a7871, 0.1) !important;
    }
    &-future {
      background-color: rgba(#2a7871, 0.1);
    }
    &-other {
      background-color: rgba(#2a7871, 0.05);

      .fc-daygrid-day-number {
        visibility: hidden;
      }
    }
  }

  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
    padding: 24px 16px 16px 16px;
    justify-content: space-between;
  }

  th {
    border: 2px solid #ffffff !important;
    border-bottom: none !important;
  }

  td {
    border: 2px solid #ffffff !important;
  }

  .fc-daygrid-day-events {
    min-height: 24px !important;
  }

  .fc-daygrid-day-bottom {
    display: none;
  }

  .selected {
    background-color: #2a7871 !important;

    .fc-daygrid-day-number {
      color: #ffffff !important;
    }

    .fc-h-event {
      .fc-event-main {
        color: #ffffff !important;
      }
    }
  }
}
